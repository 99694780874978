import React, { Component } from 'react'
import Layout from '../layout/layout'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import posed from 'react-pose'
import { easeFunction, animations } from '../helper/variables'
import ScrollManager from '../helper/scrollManager'
import Circle from '../components/graphic/circle'
import SplitText from 'react-pose-text'
import VisibilityWrapper from '../helper/visibilityWrapper'
import Sparks from '../components/graphic/sparks'
import Footer from '../components/common/footer.jsx'

import './work.css'
import Parallax from '../helper/parallax'
import Thumbnail from '../components/work/thumbnail'
import { graphql } from 'gatsby'
import { isBrowser, BrowserView, isMobile } from 'react-device-detect'
import SectionIndicator from '../components/common/sectionIndicator'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class Work extends Component {
  slideAnimation = animations('SLIDE')
  ariseAnimation = animations('ARISE')

  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    triggerPointFooter: 0,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.setState({
      mainNode: this.mainNode.current,
    })

    this.assignTriggerPoints()

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.assignTriggerPoints)

    this.props.hideSymbol()
    this.props.backgroundWhite()
    this.props.noHomepageLoading()
  }

  assignTriggerPoints = event => {
    const { momentumScrollValue } = this.state

    this.setState({
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.assignTriggerPoints)

    this.props.backgroundWhite()
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else {
      this.props.backgroundWhite()
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  render() {
    const page = this.props.data.contentfulWorkLanding
    const works = this.props.data.allContentfulWork.edges
    const { theme } = this.props
    const { momentumScrollValue } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{page.metaData.metaTitle}</title>
          <meta name="description" content={page.metaData.description} />
          <meta name="keywords" content={page.metaData.keywords} />
          <meta property="og:title" content={page.metaData.metaTitle} />
          <meta property="og:description" content={page.metaData.description} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              page.metaData.ogImage
                ? `https:${page.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta property="og:url" content="https://www.baunfire.com/work" />
          <link rel="canonical" href="https://www.baunfire.com/work" />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <Circle theme={theme} />
            <div className="content-container page-work-landing top-of-page">
              <Sparks type="3" className="spark" />
              <div className="page-header">
                <div className="header-left">
                  <h2 className="type-h11 color-red ribbon-text">
                    <SplitText
                      initialPose={isBrowser ? 'exit' : 'enter'}
                      pose="enter"
                      charPoses={this.slideAnimation}
                    >
                      {page.heroRibbonText}
                    </SplitText>
                  </h2>
                  <VisibilityWrapper>
                    {({ isVisible }) => {
                      return (
                        <h1 className={`type-h3 color-black headline`}>
                          <SplitText
                            initialPose={isBrowser ? 'exit' : 'enter'}
                            pose={isVisible ? 'enter' : 'exit'}
                            charPoses={this.ariseAnimation}
                          >
                            {page.heroHeadline}
                          </SplitText>
                        </h1>
                      )
                    }}
                  </VisibilityWrapper>
                </div>
                <div className="header-right">
                  <VisibilityWrapper>
                    {({ isVisible }) => {
                      return (
                        <div
                          className={`description type-sub3 animation ${(isVisible ||
                            isMobile) &&
                            'animation-appear'}`}
                        >
                          <Parallax
                            viewportScroll={momentumScrollValue}
                            moveRange="50"
                            disabledOnMobile
                          >
                            {page.heroDescription}
                          </Parallax>
                        </div>
                      )
                    }}
                  </VisibilityWrapper>
                </div>
              </div>
              <div className="works-list">
                {works.map((work, index) => {
                  if (index % 2 === 1) {
                    return (
                      <Parallax
                        key={work.node.id}
                        viewportScroll={momentumScrollValue}
                        moveRange="200"
                        disabledOnMobile
                      >
                        <Thumbnail data={work.node} className="even" />
                      </Parallax>
                    )
                  } else {
                    return <Thumbnail key={work.node.id} data={work.node} />
                  }
                })}
              </div>
            </div>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <SectionIndicator theme={theme}>Case Studies</SectionIndicator>
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query($page: String = "Work landing") {
    contentfulWorkLanding(title: { eq: $page }) {
      metaData {
        title
        metaTitle
        description
        keywords
      }
      heroRibbonText
      heroHeadline
      heroDescription
    }
    allContentfulWork(sort: { fields: order, order: ASC }, filter: {
      live: {
        eq: true
      }
    }) {
      edges {
        node {
          id
          order
          slug
          project
          type
          thumbnail {
            file {
              url
              fileName
              contentType
            }
            description
            fluid(maxWidth: 840, quality: 90) {
              src
              srcSet
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          metaData {
            title
            metaTitle
            description
            keywords
          }
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    hideMark: () => dispatch({ type: 'HIDE_MARK' }),
    showMark: () => dispatch({ type: 'SHOW_MARK' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedWork = connect(
  mapStateToProps,
  mapDispatchToProps
)(Work)

export default ConnectedWork
